@import 'src/shared';

.loader {
  animation: spin 2s linear infinite;
  border: 12px solid black;
  border-radius: 50%;
  border-top-color: $grey;
  height: 70px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 70px;

  &:before, &:after {
    border: 2px solid black;
    border-radius: 50%;
    content: '';
    position: absolute;
  }

  &:before {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }

  &:after {
    bottom: -14px;
    left: -14px;
    right: -14px;
    top: -14px;
  }
}

@keyframes spin {
  to {
    transform: translateY(-50%) rotate(1turn);
  }
}
