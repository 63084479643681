@import 'src/shared';

.welcome {
  @include container-center;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 600px;
  justify-content: center;
  padding-left: 7%;
  width: 600px;

  &:before {
    background: url('~assets/mouse.png') no-repeat center top;
    background-size: contain;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transform: rotate(.75turn);
    width: 100%;
  }

  &__link {
    border-bottom: 2px solid;
    color: black;
    font-size: 15px;
    padding: 8px 10px;
    position: relative;
    transition: color 0.2s;

    &:hover {
      color: $orange;
    }
  }
}
