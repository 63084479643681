.clothing {
  position: absolute;
  left: 0;

  &--top {
    top: 0;
  }

  &--middle {
    top: 72px;
  }

  &--bottom {
    top: 174px;
  }
}
