@import 'src/shared';

.home-button {
  background: white;
  border: 3px solid;
  border-radius: 7px;
  color: black;
  font-weight: bold;
  left: 40px;
  margin: auto;
  padding: 7px 15px;
  position: absolute;
  top: calc(234px / 2);
  transform: translateY(-50%);
  transition: color 0.2s;

  &:hover {
    color: $orange;
  }
}
