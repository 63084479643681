@import 'src/shared';

.home {
  @include container-center;
  align-items: center;
  background: url('~assets/mouse.png') no-repeat center top;
  background-size: contain;
  display: flex;
  flex-direction: column;
  height: 600px;
  width: 360px;

  &__link {
    border-bottom: 2px solid;
    color: black;
    font-size: 22px;
    padding: 5px 10px;
    text-transform: uppercase;

    &:not(&--disabled) {
      transition: color 0.2s;

      &:hover {
        color: $orange;
      }
    }

    &--disabled {
      color: $grey;
      cursor: not-allowed;
    }

    &:first-child {
      margin-top: 65px;
    }
  }
}
