@import 'src/shared';

.shop-item {
  align-items: center;
  background-image: linear-gradient(to bottom, white 80%, $grey 20%);
  border: 2px solid;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  height: 300px;
  margin: 6px;
  overflow: hidden;
  position: relative;
  width: 200px;

  &__image {
    height: 70%;
    margin-top: 10%;
    object-fit: scale-down;
    transition: filter 0.25s;
    width: 100%;
  }

  &__details {
    align-items: center;
    background: $grey;
    box-sizing: border-box;
    display: flex;
    font-size: 17px;
    height: 20%;
    justify-content: space-between;
    margin-top: 10%;
    padding: 0 20px;
    transition: filter 0.25s;
    width: 100%;
  }

  &__name {
    margin-right: 10px;
    text-shadow: $green 1px 1px;
  }

  &__price {
    text-shadow: $orange 1px 1px;
  }

  &__cta {
    background: rgba(255, 255, 255, .5);
    border: 2px solid;
    box-shadow: $green 1px 1px;
    font-size: 25px;
    left: 50%;
    margin: auto;
    opacity: 0;
    padding: 8px 30px;
    position: absolute;
    text-shadow: $green 1px 1px;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.25s;
  }

  &:hover & {
    &__cta {
      opacity: 1;
    }

    &__image, &__details {
      filter: blur(3px);
    }
  }
}
