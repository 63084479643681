@import 'src/shared';

body {
  background-color: $orange;
  background-image: url('~assets/colloseum.png');
  background-repeat: repeat-x;
  font-family: 'Courier New', sans-serif;
  font-weight: bold;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: inherit;
  text-decoration: none;
}

p {
  margin: 0;
}

button {
  background: transparent;
  border: none;
  cursor: pointer;
  font-family: 'Courier New', sans-serif;
  font-size: 16px;
  font-weight: bold;
}

html, body, #root {
  height: 100%;
}
