@import 'src/shared';

.profile {
  @include container-center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 20px 40px;

  &__avatar {
    display: flex;
    margin-bottom: 20px;
    position: relative;
  }

  &__text {
    @include title-font;
    align-self: flex-start;
    margin: 3px 0;
    text-transform: none;
  }
}
