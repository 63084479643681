// colors
$green: #30870A;
$orange: #D25B1B;
$grey: #707070;

@mixin white-rounded-border {
  background: white;
  border: 2px solid;
  border-radius: 50px;
  padding: 3px 8px;
}

@mixin hp-bar {
  @include white-rounded-border;
  margin-top: 10px;
}

@mixin hp-loss {
  bottom: 0;
  margin-top: 15px;
  transform: translateY(-50%);
  opacity: 0;
  position: absolute;
}

@mixin title-font {
  text-transform: uppercase;
  color: white;
  text-shadow: $green 2px 2px 1px;
}

@mixin flex-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

@mixin container-center {
  margin: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: fit-content;
}
