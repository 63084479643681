@import 'src/shared.scss';

.developer {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-right: auto;
  position: relative;

  &__weapon {
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }

  &__hp {
    @include hp-bar;

    &-loss {
      @include hp-loss;
    }
  }
}
