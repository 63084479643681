@import 'src/shared';

.available-link {
  border-bottom: 2px solid;
  color: black;
  font-size: 22px;
  padding: 5px 10px;
  text-transform: uppercase;

  &:not(&--disabled) {
    transition: color 0.2s;

    &:hover {
      color: $orange;
    }
  }

  &--disabled {
    color: $grey;
    cursor: not-allowed;
  }
}