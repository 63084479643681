@import 'src/shared';

.credits {
  @include container-center;
  @include title-font;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  padding: 20px 40px;

  &__link {
    text-decoration: underline;
    text-shadow: $green 1px 1px 1px;
  }
}
